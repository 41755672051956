import { Layout } from '../layout';
import { lazyWithRetries } from '@root/infra/router/lazy-with-retries';
import { Captcha } from '@root/modules/auth/components/captcha';
import { authSelector } from '@root/modules/auth/redux';
import { DashboardPage } from '@root/modules/dashboard/pages/dashboard.page';
import { KycHandler } from '@root/modules/kyc/components/kyc-handler';
import { useMaintenance } from '@root/modules/maintenance/hooks/use-maintenance';
import MaintenancePage from "@root/modules/maintenance/page/maintenance.page";
import { maintenanceSelector } from '@root/modules/maintenance/store';
import { LoaderScreen } from '@root/shared/ui';
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

const AuthRouter = lazyWithRetries(() => import('@root/modules/auth/router/auth.router').then(({ AuthRouter }) => ({ default: AuthRouter })));
const SettingsRouter = lazyWithRetries(() => import('@root/modules/settings/router/settings.router').then(({ SettingsRouter }) => ({ default: SettingsRouter })));
const MembershipRouter = lazyWithRetries(() => import('@root/modules/membership/router/membership.router').then(({ MembershipRouter }) => ({ default: MembershipRouter })));
const SubscriptionsRouter = lazyWithRetries(() =>
  import('@root/modules/subscriptions/router/subscriptions.router').then(({ SubscriptionsRouter }) => ({ default: SubscriptionsRouter })),
);
const OrdersRouter = lazyWithRetries(() => import('@root/modules/orders/router/orders.router').then(({ OrdersRouter }) => ({ default: OrdersRouter })));
const PaymentMethodsRouter = lazyWithRetries(() =>
  import('@root/modules/payment-methods/router/payment-methods.router').then(({ PaymentMethodsRouter }) => ({ default: PaymentMethodsRouter })),
);
const ProductsRouter = lazyWithRetries(() => import('@root/modules/products/router/products.router').then(({ ProductsRouter }) => ({ default: ProductsRouter })));
const KycRouter = lazyWithRetries(() => import('@root/modules/kyc/router/kyc.router').then(({ KycRouter }) => ({ default: KycRouter })));

export const Router = () => {
  const { authed, initialized, captchaPassed } = useSelector(authSelector);
  const isMaintenance = useSelector(maintenanceSelector);
  useMaintenance();

  if (!captchaPassed) {
    return <Captcha />;
  }

  if (!initialized) {
    return <LoaderScreen />;
  }

  if (isMaintenance) {
    return <MaintenancePage />;
  }

  const authRoutes = (
    <Routes>
      <Route path='/*' element={<AuthRouter />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  );

  const protectedRoutes = (
    <KycHandler>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<DashboardPage />} />
          <Route path='products/*' element={<ProductsRouter />} />
          <Route path='orders/*' element={<OrdersRouter />} />
          <Route path='subscriptions/*' element={<SubscriptionsRouter />} />
          <Route path='membership/*' element={<MembershipRouter />} />
          <Route path='settings/*' element={<SettingsRouter />} />
          <Route path='orders/*' element={<OrdersRouter />} />
          <Route path='payment-methods/*' element={<PaymentMethodsRouter />} />
          <Route path='/kyc/*' element={<KycRouter />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Routes>
    </KycHandler>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<LoaderScreen />}>{authed ? protectedRoutes : authRoutes}</Suspense>
    </BrowserRouter>
  );
};
