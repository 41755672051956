import { all } from '@redux-saga/core/effects';
import { authFlowSaga } from '@root/modules/auth/redux/auth/auth.saga';
import { metaFlowSaga } from '@root/modules/meta/redux';
import { countriesFlowSaga } from '@root/modules/countries/redux';
import { membershipLevelsSaga } from '@root/modules/membership/redux/membership.saga';
import { kycFlowSaga } from '@root/modules/kyc/redux/kyc.saga';
import { ordersSaga } from '@root/modules/orders/store/orders.saga';
import { productCategoriesFlowSaga } from '@root/modules/products/redux/product-categories/product-categories.saga';
import { purchasedProductsFlowSaga } from '@root/modules/products/redux/purchased-products/purchased-products.saga';
import { subscriptionsFlowSaga } from '@root/modules/subscriptions/redux/subscriptions.saga';
import { productsFlowSaga } from '@root/modules/products/redux/products/products.saga';
import { paymentMethodsSaga } from '@root/modules/payment-methods/store/payment-methods.saga';
import { billingDetailsSaga } from '@root/modules/payment-methods/store/billing-details/billing-details.saga';
import { eWalletSaga } from '@root/modules/e-wallet/store/e-wallet.saga';
import { addressesSaga } from '@root/modules/payment-methods/store/addresses/addresses.saga';
import { notificationsFlowSaga } from '@root/modules/notifications/redux/notifications.saga';
import { servicesFlowSaga } from '@root/modules/products/redux/services/services.saga';
import { socketSaga } from '../socket/saga';
import { currenciesFlowSaga } from '@root/modules/currencies/redux/currencies.saga';
import { appSettingsFlowSaga } from '@root/modules/app-settings/store/app-settings.saga';
import { maintenanceFlowSaga } from "@root/modules/maintenance/store/maintenance.saga";

export function* rootSaga() {
  yield all([
    authFlowSaga(),
    metaFlowSaga(),
    countriesFlowSaga(),
    membershipLevelsSaga(),
    productsFlowSaga(),
    ordersSaga(),
    productCategoriesFlowSaga(),
    purchasedProductsFlowSaga(),
    subscriptionsFlowSaga(),
    notificationsFlowSaga(),
    servicesFlowSaga(),
    paymentMethodsSaga(),
    billingDetailsSaga(),
    eWalletSaga(),
    addressesSaga(),
    socketSaga(),
    kycFlowSaga(),
    currenciesFlowSaga(),
    appSettingsFlowSaga(),
    maintenanceFlowSaga()
  ]);
}
